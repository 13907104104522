const gSelector = {
	html: document.querySelector('html'),
	body: document.querySelector('body'),
	header: document.querySelector('.l-header'),
};
const breakPoint = {
	sp: parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--breakSp')),
	pc: parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--breakPc'))
};

const goTop = () => {	
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
}


// ユーザーエージェント関連

const deviceCategory = {
	mobile: "ua_mobile",
	tablet: "ua_tablet",
	pc: "ua_pc"
};
const deviceParser = () => {
	const ua = navigator.userAgent;
	if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
		// スマホ
		gSelector.html.classList.add('ua_mobile');
		return deviceCategory.mobile;
	} else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
		// タブレット
		gSelector.html.classList.add('ua_tablet');
		document.querySelector('meta[name=viewport]').setAttribute('content', 'width=1300,user-scalable=yes,maximum-scale=2.0');
		return deviceCategory.tablet;
	} else{
		// PC
		gSelector.html.classList.add('ua_pc');
		return deviceCategory.pc;
	}
};
const device = deviceParser();
// example
// if(device == deviceCategory.mobile) console.log("mobile");


// タブレットviewport設定

if(device == deviceCategory.tablet){
	const viewport = document.querySelector('meta[name="viewport"]');
	viewport.setAttribute("content","width=device-1400,initial-scale=1.0,maximum-scale=1.0");
}


// ウィンドウサイズをcss変数に代入

const windowSizeProperty = (() => {
	const windowSizeSetting = () => {
		document.documentElement.style.setProperty('--vh', (window.innerHeight) + 'px');
		document.documentElement.style.setProperty('--vw', (window.innerWidth) + 'px');
	};
	let timeoutID = 0;
	let delay = 500;
	windowSizeSetting();
	if(device == deviceCategory.pc || device == deviceCategory.tablet) {
		window.addEventListener("resize", () => {
			clearTimeout(timeoutID);
			timeoutID = setTimeout(()=>{
				windowSizeSetting();
			}, delay);
		}, false);
	}
})();


// 要素が見えたらクラス追加
class scrollObserver {
	constructor(props) {
		const $this = this;
		$this.target = props.target; //対象の要素
		$this.options = props.options; // IntersectionObserverのオプション
		$this.activeClass = props.activeClass; // 付与するクラス名
		$this.observeEnd = props.observeEnd; // 要素が見えなくなった時にクラスを削除するか
		if(!$this.target) return;
		const array = Array.prototype.slice.call(document.querySelectorAll($this.target));
		const observer = new IntersectionObserver(ObserverCallback, $this.options)
		array.forEach((tgt) => {
			observer.observe(tgt)
		});
		function ObserverCallback(entries) {
			entries.forEach(function(entry, i) {
				const t = entry.target;
				if (entry.isIntersecting && !t.classList.contains($this.activeClass)) {
					t.classList.add($this.activeClass);
				} else {
					if($this.observeEnd == true)
					t.classList.remove($this.activeClass);
				}
			});
		};		
	}
}
const primaryObserver = new scrollObserver({
	target:'.js-view-observer',
	activeClass:'is-active',
	options:{
		root: null,
		rootMargin: "-20% 0px", 
		threshold: 0
	}
});


// アンカースクロール処理
class AnchorScroll {
	constructor(target) {
		const rect = target.getBoundingClientRect().top;
		const offset = window.pageYOffset;
		const gap = gSelector.header.offsetHeight + parseInt(getComputedStyle(gSelector.header).top) + 20;
		const position = rect + offset - gap;
		window.scrollTo({
			top: position,
			behavior: 'smooth',
		});
	}
}


// header
(function(){
	const button = document.querySelector('.js-header-button');
	const menu = document.getElementById('menu_dialog');
	button.addEventListener('click', (e) => {
		button.classList.toggle("active");
		menu.classList.toggle("active");
		gSelector.body.classList.toggle("is-navi-active");
	});
	window.addEventListener('scroll', () => {
		gSelector.header.style.left = -window.pageXOffset + 'px'
	})
})();


// footerページトップ
(function(){
	const pagetop = document.querySelector('.l-footer__pagetop');
	pagetop.addEventListener('click', () => goTop());
})();


// テキストアニメーション
(function(){
	const textWraps = document.querySelectorAll('.top-mv__message__content');
	Array.from(textWraps).forEach(function(wrapElement,wrapIndex) {
		const texts = wrapElement.querySelectorAll('.top-mv__message__text');
		Array.from(texts).forEach(function(textElement,textIndex) {
			const animationDelay = 0.04;
			textElement.style.animationDelay = textIndex*animationDelay+"s";
		});
	});
})();


// planページ
(function(){	
	const accordionTitle = document.querySelectorAll('.plan-accordiontitle');
	Array.from(accordionTitle).forEach(function(e) {
		e.addEventListener('click', () => {
			e.classList.toggle("active");
			const accordionBody = e.nextElementSibling;
			if(!accordionBody.classList.contains('area')) return;
			accordionBody.classList.toggle("active");
		});
	});
})();


// flowページ
(function(){	
	const accordionTitle = document.querySelectorAll('.accordiontitle');
	Array.from(accordionTitle).forEach(function(e) {
		e.addEventListener('click', () => {
			e.classList.toggle("active");
			const accordionBody = e.nextElementSibling;
			if(!accordionBody.classList.contains('area')) return;
			accordionBody.classList.toggle("active");
		});
	});
})();


// フォーム関連
(function(){	
	// 同意チェック
	const agreeCheck = () => {
		const agreeInput = document.querySelector('.js-agree-check');
		const agreeButton = document.querySelector('.js-agree-button');
		const agreeButtonClass = 'm-action-button';
		const agreeDisbiled = 'm-action-button--disbiled';
		if(!agreeInput && !agreeButton) return;
		if(!agreeButton.classList.contains(agreeButtonClass)) return;
		// クラス付与処理
		const agreeClassSet = () => {
			agreeInput.checked ? agreeButton.classList.remove(agreeDisbiled) : agreeButton.classList.add(agreeDisbiled);
		}
		// 表示時
		agreeClassSet();
		// inputクリック時
		agreeInput.addEventListener('click', (e) => {
			agreeClassSet();
		});
	}
	agreeCheck();
	// エラー画面のときはフォーム位置までスクロール
	const contactCheck = document.querySelector('.js-agree-check:checked');
	const contactPos = document.querySelector('.js-form-scroll');
	if(contactCheck && contactPos) {
		const contactScroll = new AnchorScroll(contactPos);
	}
})();